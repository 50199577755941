import { createApp, h } from 'vue'
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { createInertiaApp } from '@inertiajs/vue3'
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/de";
import relativeTime from "dayjs/plugin/relativeTime";
import objectSupport from "dayjs/plugin/objectSupport";
import dayjs from "dayjs";
import { createPinia } from 'pinia'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import {date, decimal} from "./helpers";
import '../css/tailwind.scss'
import '../css/app.css'
import 'primeicons/primeicons.css'
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// window.Pusher = Pusher;
//
// window.Echo = new Echo({
//     broadcaster: 'reverb',
//     key: import.meta.env.VITE_REVERB_APP_KEY,
//     wsHost: import.meta.env.VITE_REVERB_HOST,
//     wsPort: import.meta.env.VITE_REVERB_PORT,
//     wssPort: import.meta.env.VITE_REVERB_PORT,
//     forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';



[
    duration,
    utc,
    isoWeek,
    weekOfYear,
    isSameOrBefore,
    isBetween,
    relativeTime,
    objectSupport,
].forEach((plugin) => dayjs.extend(plugin));
dayjs.locale("de");



const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    progress: {
        color: '#29d',
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    title: (title) => `${title} - urlaubsleute.de`,
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .directive('tooltip', Tooltip)
            .use(createPinia())
            .mixin({
                methods: {
                    dayjs,
                    decimal,
                    date,
                    datetime: (date) => dayjs(date).format('DD.MM.YYYY HH:mm'),
                }
            })
            .use(PrimeVue, {
                theme: {
                    preset: Aura,
                    options: {
                        darkModeSelector: '.my-app-dark',
                    }
                }
            })
            .use(ConfirmationService)
            .use(ToastService)
            .mount(el)
    },
})
